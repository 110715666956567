import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { initReactI18next } from 'react-i18next';

export const LANGUAGES = ['de', 'fr'];
export const DEFAULT_LANGUAGE = 'de';

const languageDetector = new LanguageDetector(null, {
  order: ['querystring', 'navigator']
});

i18n
  .use(XHR)
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: DEFAULT_LANGUAGE,
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
