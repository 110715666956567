import React, { useState } from 'react';
import cx from 'classnames';

import styles from './LanguageSelect.module.scss';
import i18n, { LANGUAGES, DEFAULT_LANGUAGE } from '../../i18n';

const LanguageSelect = () => {
  const browserLanguage = i18n.language.substr(0, 2).toLowerCase();

  const [activeLanguage, setActiveLanguage] = useState(
    LANGUAGES.includes(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE
  );

  const handleLangChange = async (newLang) => {
    await i18n.changeLanguage(newLang);
    setActiveLanguage(newLang);
  };

  return (
    <div className={styles.languageSwitch}>
      {LANGUAGES.map((lang) => (
        <div
          key={lang}
          onClick={() => handleLangChange(lang)}
          role="presentation"
          className={cx(styles.langOption, {
            [styles['active']]: lang === activeLanguage
          })}
        >
          <span>{lang.toUpperCase()}</span>
        </div>
      ))}
    </div>
  );
};

export default LanguageSelect;
