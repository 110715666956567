import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Privacy from '../../../components/Privacy/Privacy';
import styles from './DesktopHome.module.scss';

const DesktopHome = () => {
  const { t } = useTranslation();

  return (
    <div
      className={styles.imageWrapper}
      style={{
        backgroundImage: 'url(/images/optimized/hirslanden-F2-min.jpeg)'
      }}
    >
      <div className={styles.textWrapper}>
        <p>{t('common.welcomeSlug')}</p>
        <p
          className={styles.intro}
          dangerouslySetInnerHTML={{
            __html: t('common.intro', {
              interpolation: { escapeValue: false }
            })
          }}
        ></p>

        <Link to="/quiz">
          <button className="basicButton">{t('quiz.start')}</button>
        </Link>
        <Privacy />
      </div>
    </div>
  );
};

export default DesktopHome;
