import cx from 'classnames';
import React, { useContext } from 'react';
import { AnswerType } from '../../common/types/answer-type.enum.ts';
import { AnswersContext } from '../../hooks/AnswerContext';
import { ANSWER_VALUES } from '../../services/QuestionService';
import styles from './Answer.module.scss';

const Answer = ({ questionId, answer, wide = false, showAnswers }) => {
  const { chosenAnswers, setChosenAnswer, setTextField } =
    useContext(AnswersContext);

  return (
    <>
      {answer.type === AnswerType.TEXT && (
        <textarea
          onChange={(e) =>
            setTextField(questionId, { ...answer, title: e.target.value })
          }
          placeholder={answer.title}
          className={styles.wide}
        ></textarea>
      )}
      {answer.type !== AnswerType.TEXT && (
        <button
          className={cx({
            [styles['active']]:
              chosenAnswers[questionId] &&
              chosenAnswers[questionId][answer.id] !== undefined,
            [styles['correct']]:
              showAnswers && answer.value === ANSWER_VALUES.CORRECT,
            [styles['wide']]: wide,
            [styles['short']]: !wide,
            [styles['wrongSelected']]:
              showAnswers && answer.value === ANSWER_VALUES.INCORRECT
          })}
          onClick={() => {
            if (!showAnswers) {
              setChosenAnswer(questionId, answer);
            }
          }}
        >
          {answer.title}
        </button>
      )}
    </>
  );
};

export default Answer;
