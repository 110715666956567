import React, { Suspense } from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { createRoot } from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.jsx';
import AnswersProvider from './hooks/AnswerContext';
import i18n from './i18n';
import './index.scss';

const container = document.getElementById('root');
const root = createRoot(container);

i18n.init().then(() => {
  root.render(
    <I18nextProvider i18n={i18n}>
      <Router>
        <Suspense fallback={<p>Loading</p>}>
          <AnswersProvider>
            <App />
          </AnswersProvider>
        </Suspense>
      </Router>
    </I18nextProvider>
  );
});
