import cx from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { AnswersContext } from '../../hooks/AnswerContext';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import styles from './DragAndDrop.module.scss';

const DragAndDrop = ({ question }) => {
  const { setOrder } = useContext(AnswersContext);
  const [answers, setAnswers] = useState(Array.from(question.possibleAnswers));

  useEffect(() => {
    setOrder(question.id, answers);
  }, [answers]);

  const getItemStyle = (draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',

    // styles we need to apply on draggables
    ...draggableStyle
  });

  const reorder = (items, origin, destination) => {
    const result = Array.from(items);
    const [removed] = result.splice(origin, 1);
    result.splice(destination, 0, removed);

    setAnswers(result);
  };

  const dragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    reorder(answers, result.source.index, result.destination.index);
  };

  if (answers.length === 0) {
    return <div>loading</div>;
  }
  return (
    <>
      <DragDropContext onDragEnd={dragEnd}>
        <Droppable droppableId="questiondroppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={cx(styles.dragBox, styles.wide)}
            >
              {answers.map((answer, index) => (
                <Draggable
                  key={answer.title}
                  draggableId={answer.title}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <div
                      className={cx(
                        styles.dnd_element,
                        snapshot.isDragging ? styles.dragging : ''
                      )}
                      style={getItemStyle(provided.draggableProps.style)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {answer.title}
                      <img
                        className={styles.dragIcon}
                        src={`${window.location.origin}/images/drag.png`}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DragAndDrop;
