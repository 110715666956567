import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Privacy from '../../../components/Privacy/Privacy';
import styles from './MobileHome.module.scss';

const backgroundStyles = [
  { imageId: 1, long: false, backgroundPosition: '-10px center' },
  { imageId: 8, long: true, backgroundPosition: '-75px center' },
  { imageId: 3, long: true, backgroundPosition: '-65px center' },
  { imageId: 9, long: false, backgroundPosition: 'center' }
].map(({ imageId, long, backgroundPosition }) => ({
  backgroundImage: `url(${window.location.origin}/images/optimized/hirslanden-F${imageId}-min.jpeg)`,
  height: long
    ? 'calc(var(--vh, vh) * 60 - 10px)'
    : 'calc(var(--vh, vh) * 40 - 10px)',
  backgroundPosition
}));

const MobileHome = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.images}>
        <div
          className={styles.imageWrapper}
          style={{
            backgroundImage: 'url(/images/optimized/hirslanden-F2-min.jpeg)'
          }}
        />
        {/* <div className={styles.imageColumn}>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[0]}
          ></div>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[1]}
          ></div>
        </div>
        <div className={styles.imageColumn} id={styles.secondColumn}>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[2]}
          ></div>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[3]}
          ></div>
        </div> */}
      </div>

      <div className={styles.textWrapper}>
        <p>{t('common.welcomeSlug')}</p>
        <p
          className={styles.intro}
          dangerouslySetInnerHTML={{
            __html: t('common.intro', {
              interpolation: { escapeValue: false }
            })
          }}
        ></p>

        <Link to="/quiz">
          <button className="basicButton">{t('quiz.start')}</button>
        </Link>
      </div>
      <div style={{ paddingBottom: '25px', paddingLeft: '25px' }}>
        <Privacy />
      </div>
    </>
  );
};

export default MobileHome;
