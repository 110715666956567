import React from 'react';
import { QuestionType } from '../../common/types/question-type.enum.ts';
import i18n from '../../i18n';
import Answer from '../Answer';
import DragAndDrop from '../DragAndDrop';
import styles from './Question.module.scss';

const Question = ({
  question: questionContent,
  questionIndex,
  children,
  showAnswers
}) => {
  return (
    <>
      <div className={styles.questionWrapper}>
        <div className={styles.imgWrapper}>
          <img
            src={
              Number(questionIndex + 1) === 5
                ? `${
                    window.location.origin
                  }/images/optimized/hirslanden-F${Number(
                    questionIndex + 1
                  )}-min-${i18n.language === 'fr' ? 'fr' : 'de'}.jpg`
                : `${
                    window.location.origin
                  }/images/optimized/hirslanden-F${Number(
                    questionIndex + 1
                  )}-min.jpeg`
            }
          />
        </div>
        <div className={styles.contentWrapper}>
          <h2
            className={styles.questionTitle}
            style={questionContent.subTitleBold ? { fontWeight: 400 } : {}}
          >
            {questionIndex + 1}. {questionContent.title}
          </h2>
          <h2
            className={styles.questionSubTitle}
            style={questionContent.subTitleBold ? { fontWeight: 600 } : {}}
          >
            {questionContent.subTitle}
          </h2>
          <div className={styles.answerWrapper}>
            {questionContent.type === QuestionType.ORDER && (
              <DragAndDrop question={questionContent} />
            )}
            {questionContent.type !== QuestionType.ORDER &&
              questionContent.possibleAnswers.map((answer) => (
                <Answer
                  questionId={questionContent.id}
                  answer={answer}
                  key={answer.id}
                  wide={questionContent.wide}
                  showAnswers={showAnswers}
                ></Answer>
              ))}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Question;
