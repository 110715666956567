import throttle from 'lodash/fp/throttle';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.scss';
import Home from './container/Home';
import Quiz from './container/Quiz';
import Results from './container/Results';

function App() {
  const onWindowResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  onWindowResize();

  const throttled = throttle(500, onWindowResize);

  window.addEventListener('resize', throttled);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/results" element={<Results />} />
      <Route path="/quiz" element={<Quiz />} />
    </Routes>
  );
}

export default App;
