import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Question from '../../components/Question/Question';
import { AnswersContext } from '../../hooks/AnswerContext';
import styles from './Quiz.module.scss';

const Quiz = () => {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showAnswers, setShowAnswers] = useState(false);
  const { score, questions, reloadLocales } = useContext(AnswersContext);

  useEffect(() => {
    reloadLocales();
  }, []);

  return (
    <>
      {questions.length > 0 && (
        <Question
          question={questions[currentQuestionIndex]}
          key={questions[currentQuestionIndex].title}
          questionIndex={currentQuestionIndex}
          showAnswers={showAnswers}
        >
          <p className={styles.alignRight}>
            {questions[currentQuestionIndex] &&
              questions[currentQuestionIndex].calculate && (
                <button
                  className="basicButton"
                  onClick={() => {
                    setShowAnswers(true);
                  }}
                >
                  {t('common.showAnswers')}
                </button>
              )}
            {currentQuestionIndex < questions.length - 1 ? (
              <button
                className="basicButton"
                onClick={() => {
                  setShowAnswers(false);
                  setCurrentQuestionIndex(currentQuestionIndex + 1);
                }}
              >
                {t('common.nextQuestion')}
              </button>
            ) : (
              <Link to="/results">
                <button className="basicButton">{t('common.results')}</button>
              </Link>
            )}
          </p>
        </Question>
      )}
      <p style={{ display: 'none' }}>Debug: Current score is {score}</p>
    </>
  );
};

export default Quiz;
