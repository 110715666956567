import React from 'react';

import LogoWrapper from '../../components/LogoWrapper';
import LanguageSelect from '../../components/LanguageSelect';
import MobileHome from './MobileHome';
import DesktopHome from './DesktopHome';
import ShowAt from '../../common/defaultscreens/ShowAt';

const Home = () => {
  return (
    <>
      <ShowAt breakpoint={'800AndBelow'}>
        <MobileHome />{' '}
      </ShowAt>
      <ShowAt breakpoint={'800AndAbove'}>
        <DesktopHome />
      </ShowAt>

      <LanguageSelect />
    </>
  );
};

export default Home;
