import { DEFAULT_LANGUAGE, LANGUAGES } from '../i18n';

export const ANSWER_VALUES = {
  CORRECT: 1,
  INCORRECT: 0
};

const getAll = async (locale = DEFAULT_LANGUAGE) => {
  const shortLocale = locale.substr(0, 2);

  let safeLocale = LANGUAGES.includes(shortLocale)
    ? shortLocale
    : DEFAULT_LANGUAGE;

  if (!LANGUAGES.includes(shortLocale)) {
    console.warn(
      `Locale ${shortLocale} is currently not supported, will use fallback: ${DEFAULT_LANGUAGE}`
    );
  }

  const { default: questions } = await import(
    `../locales/${safeLocale}/quiz.js`
  );

  return questions;
};

const QuestionService = {
  getAll
};

export default QuestionService;
