import { useState, useEffect } from 'react';
import QuestionService, { ANSWER_VALUES } from '../services/QuestionService';
import i18n from '../i18n';

function useAnswers() {
  const [chosenAnswers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getQuestions = async () => {
      const questions = await QuestionService.getAll(i18n.language);
      setQuestions(questions);
      setMaxScore(
        questions.reduce(
          (total, q) =>
            q.calculate
              ? total + q.possibleAnswers.reduce((acc, a) => acc + a.value, 0)
              : total + 1,

          0
        )
      );
    };
    getQuestions();
  }, []);

  const reloadLocales = async () => {
    const questions = await QuestionService.getAll(i18n.language);
    setQuestions(questions);
  };

  const resetAnswers = () => {
    setAnswers({});
  };

  const setChosenAnswer = async (questionId, answer) => {
    const accordingQuestion = questions.find((q) => q.id === questionId);
    const correctCount = accordingQuestion.possibleAnswers.reduce(
      (acc, answer) => (answer.value === ANSWER_VALUES.CORRECT ? acc + 1 : acc),
      0
    );

    if (correctCount > 1) {
      const newAnswers = { ...chosenAnswers };
      if (
        !newAnswers[questionId] ||
        newAnswers[questionId][answer.id] === undefined
      ) {
        setAnswers({
          ...chosenAnswers,
          [questionId]: { ...chosenAnswers[questionId], [answer.id]: answer }
        });
      } else {
        delete newAnswers[questionId][answer.id];
        setAnswers(newAnswers);
      }
    } else {
      setAnswers({
        ...chosenAnswers,
        [questionId]: { [answer.id]: answer }
      });
    }
  };

  const setTextField = async (questionId, answer) => {
    setAnswers({
      ...chosenAnswers,
      [questionId]: { ...chosenAnswers[questionId], [answer.id]: answer }
    });
  };

  const setOrder = async (questionId, answerOrder) => {
    // Check how many answers are correct for a question

    let title = answerOrder.map((a) => a.title).join(', ');

    setAnswers({
      ...chosenAnswers,
      [questionId]: { 1: { counts: 'single', title } }
    });
  };

  const result = async () => {
    let answersKeys = Object.keys(chosenAnswers);

    const results = [];

    for (let i = 0; i < questions.length; i++) {
      if (answersKeys.indexOf(String(i + 1)) > -1) {
        let question = chosenAnswers[i + 1];
        let answers = Object.values(question);
        results.push({
          questionNumber: i + 1,
          answerType: questions[i].type,
          question: questions[i].title,
          answer: answers.map((a) => a.title).join(', ')
        });
      } else {
        results.push({
          questionNumber: i + 1,
          answerType: questions[i].type,
          question: questions[i].title,
          answer: null
        });
      }
    }
    return results;
  };

  useEffect(() => {
    const newScore = Object.values(chosenAnswers)
      .map((answerObject) => Object.values(answerObject))
      .reduce((total, answerScore) => {
        if (answerScore.length === 0) {
          return total;
        } else {
          if (answerScore[0] && answerScore[0].counts === 'single') {
            return total + 1;
          } else {
            return (
              total + answerScore.reduce((prev, score) => prev + score.value, 0)
            );
          }
        }
      }, 0);

    setScore(newScore);
  }, [chosenAnswers]);

  return {
    chosenAnswers,
    score,
    setTextField,
    setOrder,
    setChosenAnswer,
    resetAnswers,
    questions,
    maxScore,
    reloadLocales,
    result
  };
}

export default useAnswers;
