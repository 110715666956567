import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Privacy from '../../components/Privacy/Privacy';
import { AnswersContext } from '../../hooks/AnswerContext';
import i18n from '../../i18n';
import { postResults } from '../../services/FetchService';
import styles from './Results.module.scss';

const Results = () => {
  const { t } = useTranslation();
  const { score, resetAnswers, result } = useContext(AnswersContext);
  const [participationSent, setParticipationSent] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [formValues, setFormValues] = useState({
    email: null,
    workPlace: 'Corporate Office',
    workType: 'am Patienten / an der Patientin resp. am Kunden / an der Kundin.'
  });

  const restart = () => {
    resetAnswers();
    window.location.href = `/?lng=${i18n.language}`;
  };

  const submit = async () => {
    try {
      await postResults(await result(), formValues);
    } catch (e) {
      setHasError(true);
      return;
    }
    setHasError(false);
    setParticipationSent(true);
    setTimeout(() => {
      window.location.href = `/?lng=${i18n.language}`;
    }, 4000);
  };

  const scoreText = t('quiz.resultReached', { score });

  return (
    <>
      {participationSent && (
        <div className={styles.participationThanks}>
          <div className={styles.thanksCard}>
            <img
              src={`${window.location.origin}/images/checked.png`}
              className={styles.checkMark}
            />
            <p>{t('quiz.participationThanks')}</p>
          </div>
        </div>
      )}
      {hasError && (
        <div className={styles.participationThanks}>
          <div className={styles.thanksCard}>
            <img
              src={`${window.location.origin}/images/warning.png`}
              className={styles.checkMark}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t('quiz.participationError', {
                  interpolation: { escapeValue: false }
                })
              }}
            ></p>
            <button onClick={restart}>{t('quiz.restart')}</button>
          </div>
        </div>
      )}
      <div
        className={styles.imageWrapper}
        style={{
          backgroundImage: 'url(/images/optimized/hirslanden-F2-min.jpeg)'
        }}
      >
        <div className={styles.textWrapper}>
          <p
            className={styles.boldText}
            dangerouslySetInnerHTML={{
              __html: scoreText
            }}
          ></p>
          <div className={styles.quizForm}>
            <div className={styles.buttons}>
              <button onClick={restart} className={styles.restart}>
                {t('quiz.restart')}
              </button>
            </div>
          </div>
          <Privacy right />
        </div>
      </div>
    </>
  );
};

export default Results;
